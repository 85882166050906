<template>

  <router-view/>
</template>
<style>
  select.form-control{
    border: none;
    box-shadow: none;
    border-radius: 0px;
  }
  .field__field{
    background-color: white !important;
  }
  .v-field--center-affix .v-field__append-inner, .v-field--center-affix .v-field__clearable, .v-field--center-affix .v-field__prepend-inner{
    background-color: white;
  }
</style>