//https://www.bezkoder.com/vue-3-authentication-jwt/#google_vignette
import { createRouter, createWebHistory } from 'vue-router'

import LoginComponent from '@/views/auth/LoginComponent.vue'
import RegisterComponent from '@/views/auth/RegisterComponent.vue'
const HomeAlumnos  = () => import('@/views/alumnos/home/HomeAlumnos.vue')

const CalendarioComponent = () => import( '@/views/admin/calendario/CalendarioComponent.vue')
const ProfileComponent = () => import("@/views/alumnos/profile/ProfileComponent.vue")
const ResidentesComponent = () => import( '@/views/admin/residentes/ResidentesComponent.vue')
const AsesoresComponent = () => import( '@/views/admin/asesores/AsesoresComponent.vue')
const UsersComponent = () => import( '@/views/admin/users/UsersComponent.vue')
const HomeAsesoresComponent = () => import( '@/views/asesores/home/HomeAsesoresComponent.vue')
const AdminLayoutComponent  = () => import( '@/views/admin/layouts/AdminLayoutComponent.vue')

//EVALUACIONES EXTERNO
const EvaluacionExternoComponent  = () => import( '@/views/externos/EvaluacionExternoComponent.vue')
const EvaluacionExternoFinalComponent  = () => import( '@/views/externos/EvaluacionExternoFinalComponent.vue')

const admin_routes=[
  
  { path:'/admin/calendario',               name:'admin_calendario',     component:CalendarioComponent },
  { path:'/admin/residentes',               name:'admin_residentes',     component:ResidentesComponent },
  { path:'/admin/asesores',               name:'admin_asesores',     component:AsesoresComponent },
  { path:'/admin/users',               name:'admin_users',     component:UsersComponent },

]

const routes=[
    { path:'/',                       name:'index',       component:LoginComponent },
    { path:'/login',                  name:'login',       component:LoginComponent },
    { path:'/register',               name:'register',     component:RegisterComponent },
    
    { path:'/miportal',               name:'alumnos',     component:HomeAlumnos },
    { path: "/profile",               name: "profile",    component: ProfileComponent, },
    { path: "/evaluacionexterno/:active/:id/:key",            name: "evaluacion_externo",    component: EvaluacionExternoComponent },
    { path: "/evaluacionexterno/:id/:key",            name: "evaluacion_externo_final",    component: EvaluacionExternoFinalComponent },
    

    
    //admin
    { path:'/admin',               name:'admin',     component:AdminLayoutComponent, children: admin_routes },
    
    //Home Asesores
    { path:'/mis-residentes',               name:'asesores',     component:HomeAsesoresComponent},

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
/*router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/'];
  /*
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
  const authRequired = !publicPages.some(page => {
    const regex = new RegExp('^' + page.replace(/:[^\s/]+/g, '[^/]+') + '$');
    return regex.test(to.path);
  });
  const loggedIn = localStorage.getItem('user');

  // Verificar si se requiere autenticación y si el usuario está autenticado
  if (authRequired && !loggedIn) {
    // Redireccionar al usuario al inicio de sesión
    next('/login');
  } else {
    // Continuar navegando
    next();
  }
});*/
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register','/evaluacionexterno','/'];
  const loggedIn = localStorage.getItem('user');
  var authRequired = true;

  for(let x=0;x<publicPages.length;x++){
    if(to.path == '/'){
      authRequired =false
      x=publicPages.length+1
    }else if(to.path.includes(publicPages[x])){
      authRequired = false
    }
  }
  

  // Verificar si la ruta actual requiere autenticación
  //const authRequired = !isPublicPage;
    console.log("REQUIRED",authRequired,to);
  // Verificar si se requiere autenticación y si el usuario está autenticado
  if (authRequired && !loggedIn) {
    // Redireccionar al usuario al inicio de sesión
    next('/login');
  } else {
    // Continuar navegando
    next();
  }
});
export default router
