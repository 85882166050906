import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js"

import "./assets/css/style.css";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import "./assets/js/script.js"
import store from "./store";
import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
// https://medium.com/@hafizzeeshan619/setting-up-laravel-10-with-vue3-and-vuetify3-26de92235baa
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure your project is capable of handling css files

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi", aliases,
    sets: {
      mdi,
      fa,
    },
  },
})

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
};

createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(VueSweetalert2,options)
    .mount('#app')
