<template>
    <div class="row">
        <div class="col-4 bgregister vh-100" ></div>
        <div class="col-8 p-4">
            <div id="login">
                <form class="form-signin"  @submit="onSubmit">

                    <img class="mb-4 my-4" src="@/assets/img/logo.png" alt="" width="400px">
                    <h1 class="h3 mb-3 my-4">Registro a mis Residencias </h1>
                    <h2 class="h6"> Periodo {{ periodo !=null ? periodo.name: "" }} </h2>
                    <div class="row">
                        <div class="form-group my-4 col-md-4 col-xs-12">
                            <label for="ap" >Apellido Paterno</label>
                            <input v-model="ap" type="text" id="ap" class="form-control" placeholder="Apellido Paterno" required autofocus>
                        </div>
                        <div class="form-group my-4 col-md-4 col-xs-12">
                            <label for="am">Apellido Materno</label>
                            <input v-model="am" type="text" id="am" class="form-control" placeholder="Apellido Materno" required>
                        </div>
                        <div class="form-group my-4 col-md-4 col-xs-12">
                            <label for="name">Nombre</label>
                            <input v-model="name" type="text" id="name" class="form-control" placeholder="Tu nombre" required >
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group my-4 col-md-4 col-xs-12">
                            <label for="n_control">Número de Control</label>
                            <input v-model="n_control"  type="text" id="n_control" class="form-control" placeholder="Número de Control" required>
                        </div>
                        <div class="form-group my-4 col-md-4 col-xs-12">
                            <label for="tel">Teléfono</label>
                            <input v-model="phone"  type="text" id="tel" class="form-control" placeholder="Teléfono" required>
                        </div>
                        <div class="form-group my-4 col-md-4 col-xs-12">
                            <label for="tel">Dirección</label>
                            <input v-model="address"  type="text" id="tel" class="form-control" placeholder="Dirección" required>
                        </div>
                    
                    
                    </div>
                    <div class="row">
                        <div class="form-group my-4 col-md-4 col-xs-12">
                            <label for="gender">Sexo</label>
                            <select   v-model="gender" name="gender" id="gender" class="form-control" required>
                                <option value="Femenino">Femenino</option>
                                <option value="Masculino">Masculino</option>
                            </select>
                        </div>
                        <div class="form-group my-4 col-md-4 col-xs-12">
                            <label for="plan">Plan Educativo</label>
                            <select  v-model="carrera"  name="carrera" id="plan" class="form-control" required>
                                <option v-bind:value="c.id" v-for="c in carreras" v-bind:key="c.id">{{ c.name }}</option>
                            </select>
                        </div>
                        <div class="form-group my-4 col-md-4 col-xs-12">
                            <label for="inputEmail">Email Institucional</label>
                            <input v-model="email"  type="email" id="inputEmail" class="form-control" placeholder="Email Institucional" required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group my-4 col-md-6 col-xs-12">
                            <label for="inputPassword">Password</label>
                            <input v-model="p1"  type="password" id="inputPassword" class="form-control" placeholder="Password" required>
                        </div>
                        <div class="form-group my-4 col-md-6 col-xs-12">
                            <label for="inputPassword2" >Confirmar Password</label>
                            <input  v-model="p2" type="password" id="inputPassword2" class="form-control" placeholder="Confirmar Password" required>
                        </div>
                    </div>
                
                    <div class="row">
                        <div class="form-group my-4 col-md-12 col-xs-12">
                            <label for="">¿Ya cuenta con empresa donde realizar sus residencias profesionales</label>
                            <br><br>
                            <select  v-model="hasEmpresa" class="form-control" required>
                                <option value="true">Si</option>
                                <option value="false">No</option>

                            </select>
                        </div>
                    
                    </div>
                    
                    <div class="form-group my-4" v-if="error!=''">
                        <div class="alert alert-danger p-1">
                            {{ error }}
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-lg btn-primary btn-block w-100 text-white" type="submit">Registrar</button>
                    </div>
                
                    
                
                </form>
            </div>
        </div>
        <v-snackbar v-model="success_alert" :timeout="3000">
            Datos Registrados Correctamente

            <template v-slot:actions>
            <v-btn color="green" variant="text" @click="success_alert = false" > Cerrar </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
 
 <script>
 import axios from 'axios';
 export default {
    name:'RegisterComponent',
    data: () => ({
        api:"",
        carreras: [],
        periodo:null,
        name:'Luis',
        ap:'Sanchez',
        am:'Sanchez',
        email: '09CG1111@itsncg.edu.mx',
        n_control:'09CG1111',
        carrera:1,
        p1:'123',
        p2:'123',
        error:'',
        hasEmpresa:false,
        phone:"636 116 222",
        address:'Calle pino',
        gender:'Masculino',
        success_alert:false
    }),
    created() {
        this.api = process.env.VUE_APP_ROOT_API

        axios.get(this.api+"carreras").then((result) => {
            console.log(result.data)
            this.carreras = result.data.data
            this.periodo = result.data.periodo
            if(this.carreras.length > 0 ){this.carrera = this.carreras[0]}
        })
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
           if(/([0-9]{2})CG[0-9]+/.test(this.n_control)){this.error="" }else{
                this.error = 'Favor de ingresar un número de control valido'
           }
           if(this.error == ""){
                if(/^\w+([.-]?\w+)*@itsncg\.edu\.mx$/.test(this.email)){
                    this.error=""
                }else{
                    this.error = 'Favor de ingresar su email institucional'
                }
           }
           if(this.error == ""){
                if(this.p1 != this.p2){
                    this.error = 'Las contraseñas no coinciden'
                }
           }
           if(this.error == ""){
                let data2 = {
                    name:this.name,
                    last:this.ap+" "+this.am,
                    email:this.email,
                    n_control:this.n_control,
                    p1:this.p1,
                    p2:this.p2,
                    carrera:this.carrera,
                    hasEmpresa: this.hasEmpresa == 'true',
                    phone:this.phone,
                    address:this.address,
                    gender:this.gender
                }
               
                axios.post(this.api+'register',data2).then((result)=>{
                    console.log(result)
                    this.success_alert=true
                    // this.$swal({
                    //     position: 'top-end',
                    //     icon: 'success',
                    //     title: 'Datos Agregados Correctamente',
                    //     showConfirmButton: false,
                    //     timer: 1500
                    // })
                    this.$router.push('/login');
                }).catch((error)=>{
                    console.log(error)
                    if(error.response.data.message.includes('Duplicate')){
                        this.error = 'El email ingresado ya ha sido registrado anteriormente'
                    }else{
                        this.error = 'Ocurrió un error al guardar la solicitud'
                    }
                   
                    
                });
           }
           
        }
    },
    watch: {
        email(){ this.error = ""; },
        n_control(){ this.error = "";},
        p1(){ this.error = "";},
        p2(){ this.error = "";},
    },

 }
 </script>
 
 <style>
 body{
    overflow: scroll;
 }
 #login {
   display: -ms-flexbox;
   display: -webkit-box;
   display: flex;
   -ms-flex-align: center;
   -ms-flex-pack: center;
   -webkit-box-align: center;
   align-items: center;
   -webkit-box-pack: center;
   justify-content: center;
   padding-top: 40px;
   padding-bottom: 40px;
 }
 
 .form-signin {
   width: 100%;
   max-width: 730px;
   padding: 15px;
   margin: 0 auto;
 }
 .form-signin .checkbox {
   font-weight: 400;
 }
 .form-signin .form-control {
   position: relative;
   box-sizing: border-box;
   height: auto;
   padding: 10px;
   font-size: 16px;
 }
 .form-signin .form-control:focus {
   z-index: 2;
 }
 .form-signin input[type="email"] {
   margin-bottom: -1px;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
 }
 .form-signin input[type="password"] {
   margin-bottom: 10px;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
 }
 .bgregister{
    background-color: #512f80;
  
  }
 </style>