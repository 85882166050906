<template>
  <div class="row">
    <div class="col-8 bg vh-100" >a</div>
    <div class="col-4 p-4">
      <div class="text-center p-4" id="login">
        <Form class="form-signin" @submit="handleLogin" :validation-schema="schema">
            <div class="row my-4">
              <img  src="@/assets/img/logo.png" class="col-12 logo">
            </div>
            <br>
            <br>
            <br>
             <h1 class="h3 mb-3 my-4">Ingresar al portal Residencias </h1>
            <div class="form-group my-4">
                <label for="inputEmail" class="sr-only">Email address</label>
                <Field name="username" type="text" class="form-control" value="lgrijalva@itsncg.edu.mx" />
                <ErrorMessage name="username" class="text-danger p-2" />
            </div>
            <div class="form-group my-4">
                <label for="inputPassword" class="sr-only">Password</label>
              
              <Field name="password" type="password" class="form-control" />
              <ErrorMessage name="password" class="text-danger p-2" />
            </div>
        
            <div class="form-group my-4">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
            </div>  
            <div class="form-group my-4">
              <router-link to="/register" class="btn btn-link">Crear cuenta</router-link>
            </div>

        
            <div class="form-group my-4">
              <button class="btn btn-primary btn-block" :disabled="loading">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Login</span>
              </button>
            </div>
          
       
        </Form>
   </div>
    </div>
  </div>
  
</template>

<script>
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from "yup";
export default {
    name:'LoginComponent',
    components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Email requerido"),
      password: yup.string().required("Password requerido"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  created() {
    if (this.loggedIn) {
      if(this.currentUser.roles != null){
        this.currentUser.roles.forEach(element => {
              if(element=='Admin'){
                this.$router.push("/admin/residentes");
              }else if(element == 'Asesor'){
                this.$router.push("/mis-residentes");
              }else{
                this.$router.push("/miportal");
              }
            });
        }
      //this.$router.push("/profile");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        (user) => {
          console.log("USERRRRR",user)
          if(typeof user === 'undefined'){
            this.message = 'Credenciales Incorrectas'
            this.loading = false
          }else{
            
            user.roles.forEach(element => {
              if(element=='Admin'){
                this.$router.push("/admin/residentes");
              }else if(element == 'Asesor'){
                this.$router.push("/mis-residentes");
              }else{
                this.$router.push("/miportal");
              }
            });
          }
         
        // 
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.message = 'Credenciales incorrectas'
        }
      );
    },
  }
}
</script>

<style>
  body{
    overflow: hidden;
  }
#login {
  /*#512f80*/
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bg{
  background-image: url('@/assets/img/fondo_login.jpg');
  background-size: cover;

}

.logo{

  height: 200px;
}
</style>